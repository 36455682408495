import React from "react";
import styled from "styled-components";

const services = [
  "Commercial",
  "Residential",
  "Deck & Patio",
  "Driveway",
  "Sidewalk",
  "Roof",
  "...",
];

const ServicesContainer = styled.div`
  padding: 20px;
`;

const Heading = styled.h2`
  font-size: 2rem;
  margin-bottom: 10px;
`;

const ServiceList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ServiceItem = styled.li`
  margin: 5px 0;
  font-size: 1.2rem;
`;

const Services = () => (
  <ServicesContainer>
    <Heading>Our Services</Heading>
    <ServiceList>
      {services.map((service, index) => (
        <ServiceItem key={index}>{service}</ServiceItem>
      ))}
    </ServiceList>
  </ServicesContainer>
);

export default Services;
