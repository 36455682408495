import React from "react";
import "./App.css";
// import Logo from "./components/Logo";
import Summary from "./components/Summary";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Bubbles from "./components/Bubbles";

function App() {
  return (
    <div className="App">
      <Bubbles />
      <Summary />
      <Services />
      <Contact />
      {/* <Logo /> */}
    </div>
  );
}

export default App;
