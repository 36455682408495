import React, { useEffect } from "react";
import styled from "styled-components";

const BubbleContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
`;

const Bubbles = () => {
  useEffect(() => {
    const container = document.getElementById("bubble-container");
    if (container) {
      for (let i = 0; i < 30; i++) {
        const bubble = document.createElement("div");
        bubble.className = "bubble";
        bubble.style.width = `${20 + Math.random() * 30}px`;
        bubble.style.height = `${20 + Math.random() * 30}px`;
        bubble.style.left = `${Math.random() * 100}%`;
        bubble.style.animationDuration = `${10 + Math.random() * 10}s`;
        container.appendChild(bubble);
        const bubble2 = document.createElement("div");
        bubble2.className = "bubble2";
        bubble2.style.width = `${20 + Math.random() * 30}px`;
        bubble2.style.height = `${20 + Math.random() * 30}px`;
        bubble2.style.left = `${Math.random() * 100}%`;
        bubble2.style.animationDuration = `${10 + Math.random() * 10}s`;
        container.appendChild(bubble2);
      }
    }
  }, []);

  return <BubbleContainer id="bubble-container"></BubbleContainer>;
};

export default Bubbles;
