import React from "react";
import styled from "styled-components";

const SummaryContainer = styled.div`
  padding: 20px;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
`;

const Summary = () => (
  <SummaryContainer>
    <div className="bubble-title">Soft Spray Solutions</div>
    <Paragraph>
      We offer top-notch services to keep your property clean and sparkling.
    </Paragraph>
  </SummaryContainer>
);

export default Summary;
